<template>
  <div>
    <div class="a">
      <img class="one" src="../../../assets/img/WAP产品分类3-整体体塑_02.jpg" />
    </div>
    <div class="b">
      <img class="one" src="../../../assets/img/WAP产品分类3-整体体塑_03.jpg" />
    </div>
    <div class="c">
      <img class="one" src="../../../assets/img/WAP产品分类3-整体体塑_04.jpg" />
    </div>
     <div class="d">
      <img class="one" src="../../../assets/img/WAP产品分类3-整体体塑_05.jpg" />
    </div>
     <div class="e">
      <img class="one" src="../../../assets/img/WAP产品分类3-整体体塑_06.jpg" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
// .e{
//     height: 406px;
// }
// .d{
//     height: 591px;
// }
// .c{
//     height: 523px;
// }
.b{
    height: 240px;
}
.a{
    height: 170px;
}
</style>